import i18next from "i18next"

import { ApiError } from "../../enums/ApiErrorCode"
import {
  ALLIANZ_EWKA_INTEGRATION_TYPE,
  ALLIANZ_ONYX_INTEGRATION_TYPE,
  S7HEALTH_INTEGRATION_TYPE,
  SIGNAL_IDUNA_INTEGRATION_TYPE
} from "../../utils/getIntegrationType"

export const getInsuranceVerificationErrorData = (errorType: number | null, integrationType: number|null, contactPhoneNumber: string|null) => {
  if (integrationType === SIGNAL_IDUNA_INTEGRATION_TYPE) {
    switch (errorType) {
      case ApiError.SIGNAL_IDUNA_INTEGRATION_NO_INSURANCE:
        return i18next.t("exam:signalIdunaPolicyNotActiveLabel", {contactPhoneNumber: contactPhoneNumber})
      case ApiError.SIGNAL_IDUNA_INTEGRATION_NOT_AVAILABLE:
      case ApiError.SIGNAL_IDUNA_INTEGRATION_NO_VALID_INSURANCE_PROCEDURES:
        return i18next.t("exam:signalIdunaNotAvailable", {contactPhoneNumber: contactPhoneNumber})
      case ApiError.SIGNAL_IDUNA_INTEGRATION_VER_REQ:
        return i18next.t("exam:signalIdunaVerReqError", {contactPhoneNumber: contactPhoneNumber})
      case ApiError.SIGNAL_IDUNA_INTEGRATION_AVAILABLE_CONF_REQ:
        return i18next.t("exam:signalIdunaNotAvailable", {contactPhoneNumber: contactPhoneNumber})
      case ApiError.SIGNAL_IDUNA_INTEGRATION_NOT_AVAILABLE_ERROR:
        return i18next.t("exam:signalIdunaNotAvailableError", {contactPhoneNumber: contactPhoneNumber})
      default:
        return i18next.t("exam:signalIdunaPolicyNotActiveLabel", {contactPhoneNumber: contactPhoneNumber})
    }
  }

  if (integrationType === S7HEALTH_INTEGRATION_TYPE) {
    switch (errorType) {
      case ApiError.S7HEALTH_INSURANCE_SUSPENDED:
        return i18next.t("exam:s7Health:insuranceSuspended", {contactPhoneNumber: contactPhoneNumber})
      case ApiError.S7HEALTH_NO_BOOKING_PERMISSION:
      case ApiError.S7HEALTH_INSURANCE_INACTIVE:
      default:
        return i18next.t("exam:s7Health:insuranceInactive", {contactPhoneNumber: contactPhoneNumber})
    }
  }

  if (integrationType === ALLIANZ_EWKA_INTEGRATION_TYPE || integrationType === ALLIANZ_ONYX_INTEGRATION_TYPE) {
    switch (errorType) {
      case ApiError.ALLIANZ_EWKA_INSURANCE_INACTIVE:
      case ApiError.ALLIANZ_ONYX_INSURANCE_INACTIVE:
        return i18next.t("exam:allianzEwka:insuranceInactiveDescription", {contactPhoneNumber: contactPhoneNumber})
    }
  }

  return i18next.t("exam:signalIdunaPolicyNotActiveLabel", {contactPhoneNumber: contactPhoneNumber})
}

export const getInsuranceVerificationErrorLabel = (errorType: number | null, integrationType: number|null) => {
  if (integrationType === SIGNAL_IDUNA_INTEGRATION_TYPE) {
    return i18next.t("consultation:archiveTags:otherError")
  }

  if (integrationType === S7HEALTH_INTEGRATION_TYPE) {
    switch (errorType) {
      case ApiError.S7HEALTH_INSURANCE_SUSPENDED:
        return i18next.t("exam:s7Health:insuranceSuspendedLabel")
      case ApiError.S7HEALTH_NO_BOOKING_PERMISSION:
      case ApiError.S7HEALTH_INSURANCE_INACTIVE:
      default:
        return i18next.t("exam:s7Health:insuranceInactiveLabel")
    }
  }

  if (integrationType === ALLIANZ_EWKA_INTEGRATION_TYPE || integrationType === ALLIANZ_ONYX_INTEGRATION_TYPE) {
    switch (errorType) {
      case ApiError.ALLIANZ_EWKA_INSURANCE_INACTIVE:
        return i18next.t("exam:allianzEwka:insuranceInactiveTitle")
    }
  }

  return i18next.t("consultation:archiveTags:otherError")
}