import React, { ChangeEvent, FC, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RouteComponentProps, useHistory, useParams } from "react-router"
import { Box, Button, CircularProgress, TextField, Typography, useTheme } from "@material-ui/core"
import { AxiosError, CancelTokenSource } from "axios"
import { format } from "date-fns"
import i18next from "i18next"
import _isEmpty from "lodash.isempty"
import { useDebounce } from "use-debounce"

import api, { ApiInstance } from "../../api/api"
import { getConsultationExamReferralsConfig, postExamCartConfig, postUserFileConfig } from "../../api/routes"
import { ApiError } from "../../enums/ApiErrorCode"
import { V4ErrorType } from "../../enums/V4ErrorType"
import { useAppSelector } from "../../hooks/storeHooks"
import useGetFormValidationRules from "../../hooks/useGetFormValidationRules"
import { analytics, LogEventType } from "../../services/analytics"
import store from "../../store"
import { selectClinicSettings } from "../../store/clinic/clinicSettings.selectors"
import { selectUserId } from "../../store/session/session.selectors"
import { selectHasUserCompletePersonalData } from "../../store/user/user.selectors"
import { useCalculateServicePricesQuery } from "../../store/vouchers/vouchers.api"
import AppDialog from "../../components/common/appDialog/AppDialog.component"
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader.component"
import DocumentationLoader from "../../components/common/lottieAnimations/animations/DocumentationLoader.component"
import PageSection from "../../components/common/pageSection/PageSection.component"
import GlobalFormErrorMessage from "../../components/commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import ActiveInsuranceFormFields
  from "../../components/exam/activeInsuranceFormFields/ActiveInsuranceFormFields.component"
import ConsultationOrderedExamsList
  from "../../components/exam/consultationOrderedExamsList/ConsultationOrderedExamsList.component"
import { ConsultationExamReferral } from "../../components/exam/Exam.types"
import ExamsSelect from "../../components/exam/examsSelect/ExamsSelect.component"
import { ExamListItem } from "../../components/exam/examsSelect/ExamsSelect.types"
import OrderedExamsList from "../../components/exam/orderedExamsList/OrderedExamsList.component"
import IconByIntegrationType from "../../components/iconByIntegrationType/IconByIntegrationType"
import FillUserDataInformationPanel
  from "../../components/informationPanel/fillUserDataInformationPanel/FillUserDataInformationPanel.component"
import OrderExaminationsByAlabForm
  from "../../components/orderExaminationsByAlabForm/OrderExaminationsByAlabForm.component"
import { checkFeatureAvailability } from "../../store/clinic/clinicSettings.utils"
import { isBaseQueryError } from "../../store/store.utils"
import { getExamOptionName } from "../../utils/getExamOptionName"
import { getIntegrationType } from "../../utils/getIntegrationType"
import { redirectToError404Page, redirectToError500Page } from "../../utils/handleErrors"
import { getInsuranceVerificationErrorData, getInsuranceVerificationErrorLabel } from "./OrderExamsPage.utils"
import { RoutePath } from "../../routes/Routes.types"
import { ConsultationExamReferralsResponse } from "../../store/examReferrals/examReferrals.types"
import {
  ExamsCartErrorResponse,
  ExamsCartErrors,
  ExamsCartResponse, OrderExamsError400Response,
  OrderExamsFormValues,
  OrderExamsGlobalError,
  OrderExamsValuesState
} from "./OrderExamsPage.types"

import { ExamsRegulationsValidationSchema } from "./ExamsRegulations.validation"

interface OrderExamsPageParams {
  consultationId?: string;
}

const formValuesInitialState: OrderExamsValuesState = {
  exams: [],
  examReferrals: [],
  voucher: "",
  laboratory: null,
}

const TYPE_PATIENT_REFERRAL = "5"

const OrderExamsPage: FC<RouteComponentProps<OrderExamsPageParams>> = () => {
  const { consultationId } = useParams<OrderExamsPageParams>()
  const { push } = useHistory()
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const patientId = useAppSelector(selectUserId) || ""
  const {
    patientLaboratories,
    clinicSignalIdunaSettings: { insuranceVerificationEnabled },
    frontendSettings: { modules },
    clinicAllianzSettings: { ewkaVerificationEnabled, onyxVerificationEnabled }
  } = useAppSelector(selectClinicSettings)
  const isAllianzEnabled = ewkaVerificationEnabled || onyxVerificationEnabled
  const isInsuranceVerificationEnabled = insuranceVerificationEnabled || modules.includes("s7health") || isAllianzEnabled
  const patientUploadReferralsEnabled = checkFeatureAvailability().patientUploadReferralsEnabled
  const [values, setValues] = useState<OrderExamsValuesState>(formValuesInitialState)
  const [loading, setLoading] = useState<boolean>(false)
  const [sending, setSending] = useState<boolean>(false)
  const [alreadyAddedExamName, setAlreadyAddedExamName] = useState("")
  const [uploadErrors, setUploadErrors] = useState<string[]>([])
  const [errors, setErrors] = useState<ExamsCartErrors>({})
  const [globalError, setGlobalError] = useState<OrderExamsGlobalError|null>(null)
  const [errorType, setErrorType] = useState<number|null>(null)
  const [consultationExamsWithError, setConsultationExamsWithError] = useState<ConsultationExamReferral[]>([])
  const submitDisabled = values.exams.length < 1 && values.examReferrals.length < 1
  const [file, setFile] = useState<File | null>(null)
  const [step, setStep] = useState<number>(1)
  const [examsToOrder, setExamsToOrder] = useState<ConsultationExamReferral[]>([])
  const [code] = useDebounce(values.voucher, 1000)
  const [noInsurancePopupErrorVisible, setNoInsurancePopupErrorVisible] = useState(false)
  const contactPhoneNumber = store.getState().clinic?.clinicSettings?.frontendSettings?.contactPhoneNumber
  const hasUserCompletePersonalData = useSelector(selectHasUserCompletePersonalData)
  const hideConsultationBooking = checkFeatureAvailability().hideConsultationBooking

  const form = useForm({
    defaultValues: {
      referralConfirmation: false,
    },
    mode: "onSubmit",
    resolver: isInsuranceVerificationEnabled && !consultationId
      ? useGetFormValidationRules(ExamsRegulationsValidationSchema)
      : undefined,
  })
  
  const { data = {}, isLoading, isFetching, error } = useCalculateServicePricesQuery(
    {
      subscription_coupon_code: code,
      currency_code: "PLN",
      exam_ids: consultationId
        ? values.examReferrals.map(exam => Number(exam.id))
        : values.exams.map(exam => exam.id),
      laboratory_id: patientLaboratories[0],
    },
    {
      skip: _isEmpty(consultationId
        ? values.examReferrals.map(exam => Number(exam.id))
        : values.exams.map(exam => exam.id))
    }
  )

  const exams = values.exams?.map((exam) => {
    const priceAfterDiscount = data[String(exam.id)]
    
    return ({
      ...exam,
      priceAfterDiscount: priceAfterDiscount === Number(exam.labPrice)
        ? undefined
        : priceAfterDiscount,
    })
  })
  
  const examReferrals = values.examReferrals?.map((exam) => {
    const priceAfterDiscount = data[String(exam.id)]
    
    return {
      ...exam,
      priceAfterDiscount: priceAfterDiscount === Number(exam.lab_price)
        ? undefined
        : priceAfterDiscount,
    }
  })

  const handleChange = (prop: keyof OrderExamsValuesState) => (event: ChangeEvent<HTMLInputElement>) => {
    setValues({...values, [prop]: event.target.value})
  }

  const handleExamsChange = (event: ChangeEvent<unknown>, newExam: ExamListItem | null) => {
    if (newExam) {
      if (!values.exams.includes(newExam)) {
        if (!values.exams?.filter(exam => exam.id === newExam.id).length) {
          setValues({
            ...values,
            exams: [...values.exams, newExam]
          })
        } else {
          setAlreadyAddedExamName(getExamOptionName(newExam, isAllianzEnabled))
        }

        return
      }

      setAlreadyAddedExamName(getExamOptionName(newExam, isAllianzEnabled))
    }
  }

  const removeExam = (examId: number) => {
    setValues({
      ...values,
      exams: values.exams.filter(exam => exam.id !== examId)
    })
  }

  const handleFileChange = (files: File[]) => {
    setFile(files.length ? files[0] : null)
  }

  const handleCancelButton = () => {
    form.setValue("referralConfirmation", false)
    setStep(1)
  }

  const getConsultationExamReferrals = async (cancelToken?: CancelTokenSource["token"]) => {
    setLoading(true)

    try {
      const { data: { referrals }} = await api.request<ConsultationExamReferralsResponse>({
        ...getConsultationExamReferralsConfig(consultationId || ""),
        cancelToken,
      })

      if (referrals.length === 0) {
        push(`/${i18n.language}/404}`)
      }

      setValues({
        ...values,
        examReferrals: referrals.filter(exam => !!exam.hl7_code),
      })

      setConsultationExamsWithError(referrals.filter(exam => !exam.hl7_code))
    } catch (e) {
      if (api.isCancel(e)) return

      console.error(e)
      redirectToError500Page(e)
      redirectToError404Page(e)
    }

    setLoading(false)
  }

  const createExamCart = async () => {
    if (isAllianzEnabled) {
      const formattedExams = consultationId
        ? values.examReferrals
        : exams.map(exam => ({
          lab_name: getExamOptionName(exam, isAllianzEnabled),
          lab_price: exam.labPrice,
          is_bought: false,
          id: exam.id.toString(),
          hl7_code: exam.HL7Code,
          included_in_package: true,
          priceAfterDiscount: exam.priceAfterDiscount
        }))
      
      setExamsToOrder(formattedExams)
      setStep(2)

      return
    }

    setErrors({})
    setSending(true)
    
    const data: OrderExamsFormValues = {
      patient_id: patientId,
      consultation_id: consultationId,
      laboratory_id: patientLaboratories[0],
      exams: consultationId
        ? values.examReferrals.map(exam => exam.id)
        : values.exams.map(exam => exam.id),
      subscription_coupon: values.voucher,
      language: i18n.language,
      exam_point_id: values?.laboratory?.id,
      payment_redirect_url: `${window.location.origin}/${i18n.language}${RoutePath.EXAMS_PAYMENT_SUCCESSFULL}`,
    }
    
    try {
      const { data: { status, redirect }} = await api.request<ExamsCartResponse>({
        ...postExamCartConfig,
        data
      })

      analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_EXAMINATION_ORDER_CART_SUCCESS)

      if (status === "redirect") {
        if (redirect.includes("patient/exam/order")) {  // v4 exams url: /patient/exam/order
          // when payment was 0 (for example due to subscription_coupon usage)
          push(`/${i18n.language}`)
        } else { // payu link
          window.location.href = redirect
        }
      }
    } catch (e) {
      console.error(e)
      
      const errorLog = {
        "error_code": e.response?.status,
        "error_name": JSON.stringify(e.response?.data),
      }
      
      analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_EXAMINATION_ORDER_CART_ERROR, errorLog)
      
      //we get error 500 when at least one of exams doesn't have HL7Code
      if (e.response?.data.errorType === V4ErrorType.VOUCHER_ERROR) {
        setGlobalError({
          status: 400,
          message: t("errors:Voucher doesn't exists"),
        })
        
        setSending(false)
        return
      }

      if (e.response?.status === 500) {
        setGlobalError({
          status: 500,
          message: t("errors:unknownError"),
        })
      }
      
      if (e.response?.status === 400) {
        const message = (e as AxiosError<OrderExamsError400Response>).response?.data.detail
        const errorType = e?.response?.data?.errorType

        if (isInsuranceVerificationEnabled && errorType) {
          setNoInsurancePopupErrorVisible(true)
          setErrorType(errorType)
        } else {
          setGlobalError({
            status: 400,
            message: t(`referrals:${message}`),
          })
        }
      }
      
      if ((e as AxiosError<ExamsCartErrorResponse>).response?.data?.errors) {
        setErrors(e?.response?.data?.errors || null)
      }
    }
    
    setSending(false)
  }

  const handleSubmit = form.handleSubmit(async () => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_EXAMINATION_ORDER_BUTTON_CLICK)
    
    const formData = new FormData()

    if (
      ((!isInsuranceVerificationEnabled && !patientUploadReferralsEnabled) || consultationId)
        || isAllianzEnabled
    ) {
      await createExamCart()
      
      return
    }

    if (!file) {
      setUploadErrors([t("documents:addFile")])
      
      return
    }

    setSending(true)

    formData.append("file", file)
    formData.append("type", TYPE_PATIENT_REFERRAL)

    try {
      await api.request({
        ...postUserFileConfig,
        data: formData
      })

      setSending(false)
      setFile(null)
      
      await createExamCart()
    } catch (e) {
      if (e.response?.data?.error === ApiError.FILE_UPLOAD_ERROR) {
        setUploadErrors(([t("errors:An infected file has been loaded, it has been deleted.")]))
      } else if (e.response?.data?.errors) {
        setUploadErrors([t("errors:wrongFileFormat")])
      }
      
      setSending(false)
    }
  })

  const getViewByStep = (step: number) => {
    if (step === 1) {
      return <>
        <PageSection title={t("exam:orderExams")}>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit}>
              <Box
                mb={7}
                p={5.5}
                borderRadius={theme.shape.borderRadius}
                component="section"
                bgcolor={theme.palette.background.paper}
              >
                {/*HIDDEN - waiting for switch from get exam points web endpoint to api endpoint*/}
                {/*<LaboratorySelect*/}
                {/*  value={values.laboratory}*/}
                {/*  onChange={handleChange("laboratory")}*/}
                {/*  disabled={sending}*/}
                {/*  label={t("laboratory:selectLaboratory")}*/}
                {/* />*/}
                {consultationId
                  ? (
                    <ConsultationOrderedExamsList
                      examsToBuy={examReferrals}
                      examsWithError={consultationExamsWithError}
                    />
                  ) : (
                    <>
                      <ExamsSelect
                        value={values.exams}
                        onChange={handleExamsChange}
                        disabled={sending}
                        label={t("exam:searchExam")}
                      />

                      {exams.length > 0 && (
                        <OrderedExamsList
                          exams={exams}
                          removeExam={removeExam}
                        />
                      )}
                      <ActiveInsuranceFormFields
                        setUploadErrors={setUploadErrors}
                        uploadErrors={uploadErrors}
                        handleFileChange={handleFileChange}
                        insuranceVerificationEnabled={isInsuranceVerificationEnabled || patientUploadReferralsEnabled}
                      />
                    </>
                  )}
                <Box display="flex"
                  justifyContent={isInsuranceVerificationEnabled ? "flex-end" : "space-between"}
                  alignItems="center"
                  mt={isInsuranceVerificationEnabled ? 3 : 0}
                >
                  {
                    !isInsuranceVerificationEnabled && (
                      <Box
                        display='flex'
                        alignItems='center'
                      >
                        <TextField
                          id="voucher"
                          label={t("voucher")}
                          value={values.voucher}
                          onChange={handleChange("voucher")}
                          error={!!errors["subscription_coupon"]?.length}
                          helperText={errors["subscription_coupon"]?.map(errorMessage => t(`errors:${errorMessage}`))
                            .join("\u000A")
                          }
                        />
                        {
                          isLoading && isFetching && (
                            <Box
                              display='flex'
                              alignItems='center'
                              ml={2}
                            >
                              <Box mr={1}>
                                <CircularProgress size={24}/>
                              </Box>
                              <Typography>
                                {t("exam:calculatingPrice")}
                              </Typography>
                            </Box>
                          )
                        }
                      </Box>
                    )
                  }
                  <Button
                    disabled={sending || submitDisabled}
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={sending
                      ? <ButtonLoader position="prefix"/>
                      : <IconByIntegrationType iconName={"icon-check"}/>
                    }
                  >
                    <Typography>
                      {t(`exam:${isAllianzEnabled ? "orderExams" : "order"}`)}
                    </Typography>
                  </Button>
                </Box>
                {globalError && (
                  <GlobalFormErrorMessage message={globalError.message}/>
                )}
              </Box>
            </form>
          </FormProvider>
          <AppDialog
            open={alreadyAddedExamName !== ""}
            onClose={() => setAlreadyAddedExamName("")}
            title=""
          >
            <Box my={2}>
              {t("exam:examAlreadyAdded", {examName: alreadyAddedExamName})}
            </Box>
          </AppDialog>
        </PageSection>
      </>
    }

    return <OrderExaminationsByAlabForm
      consultationId={consultationId}
      referrals={examsToOrder}
      visitAtDate={format(new Date(), "yyyy-MM-dd")}
      cancelButtonAction={handleCancelButton}
    />
  }

  useEffect(() => {
    if (isBaseQueryError(error) && error.status === 500) {
      setGlobalError({
        status: 500,
        message: t("errors:unknownError"),
      })
    }
  }, [error])

  useEffect(() => {
    analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_EXAMINATION_PAGE_LOAD)
  },[])

  useEffect(() => {
    const requestSource = (api as ApiInstance).CancelToken.source()

    if (consultationId) {
      getConsultationExamReferrals(requestSource.token)
    }
    
    return () => {
      setGlobalError(null)
      setErrorType(null)
      setNoInsurancePopupErrorVisible(false)
      setErrors({})
      setValues(formValuesInitialState)
      setConsultationExamsWithError([])
      requestSource.cancel("Request interrupted by page change")
    }
  }, [consultationId])

  if (!checkFeatureAvailability().exams && consultationId) {
    push(`/${i18n.language}/404`)
  }

  if (hideConsultationBooking) {
    push(`/${i18next.language}`)

    return null
  }

  if (!hasUserCompletePersonalData) {
    return <FillUserDataInformationPanel redirectRoute={`/${i18next.language}${RoutePath.MAKE_CONSULTATION}`} />
  }

  if (loading) {
    return (
      <Box className="page-loader-box">
        <DocumentationLoader/>
      </Box>
    )
  }

  return (
    <>
      {getViewByStep(step)}
      <AppDialog
        open={noInsurancePopupErrorVisible}
        title={getInsuranceVerificationErrorLabel(
          errorType,
          getIntegrationType(
            insuranceVerificationEnabled,
            modules.includes("s7health"),
            ewkaVerificationEnabled,
            onyxVerificationEnabled
          ),
        )}
        onClose={() => setNoInsurancePopupErrorVisible(false)}
        closeButtonText={t("close")}
        style={{whiteSpace: "pre-line"}}
      >
        {getInsuranceVerificationErrorData(
          errorType,
          getIntegrationType(
            insuranceVerificationEnabled,
            modules.includes("s7health"),
            ewkaVerificationEnabled,
            onyxVerificationEnabled
          ),
          contactPhoneNumber
        )}
      </AppDialog>
    </>
  )
}

export default OrderExamsPage
